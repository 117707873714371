import React, { useRef } from "react";
import { StaticQuery, graphql } from "gatsby";
import Section, { SectionContent } from "./sections/Section";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link, AccentLinkButton, H2 } from "./Common";
import PropTypes from "prop-types";
import theme from "src/components/theme";

const MENU_ITEMS = [
  {
    title: "Story",
    url: "/#story",
    type: "link",
  },
  {
    title: "Download",
    url: "/download",
    type: "link",
  },
  {
    title: "Pricing",
    url: "/pricing",
    type: "link",
  },
  {
    title: "Blog",
    url: "/blog",
    type: "link",
  },
  {
    title: "Sign In",
    url: "https://app.appspector.com/login",
    type: "button",
  },
];

export const HeaderSectionContainer = styled(Section)`
  overflow: visible;
  position: relative;
  background-color: rgb(40, 40, 49);

  &:before {
    background-image: linear-gradient(to top, #a8a8bb, #323249);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  ${SectionContent} {
    position: relative;
    z-index: 1;
    padding: 0;

    @media (min-width: ${theme.mobileBreakpoint}) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: -7% 0 0 0;
  padding: 40px 16px 0 16px;
  transform: translateY(7%);
  width: 100%;

  @media (min-width: ${theme.mobileBreakpoint}) {
    padding: 40px 40px 0 40px;
  }
`;

export const HeaderTitle = styled(H2)`
  max-width: 500px;
  font-weight: normal;
  text-align: center;
  margin: 0 0 40px 0;
`;

const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  position: relative;

  border-bottom: 1px solid rgba(151, 151, 151, 0.1);

  @media (min-width: ${theme.topMenuBreakpoint}) {
    border-bottom: none;
  }
`;

const LogoLink = styled(Link)`
  line-height: 0;
`;

const LogoImg = styled(Img)`
  min-width: 158px;
  max-width: 158px;
  min-height: 36px;
  max-height: 36px;
  width: 158px;
  height: 36px;
  margin: 16px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin: 40px;
  }
`;

const Menu = styled.div`
  flex-basis: 100%;
  border-top: 1px solid rgba(151, 151, 151, 0.1);
  overflow: hidden;
  height: ${p => (p.open ? p.contentHeight : 0)}px;
  transition: 0.2s;

  @media (min-width: ${theme.topMenuBreakpoint}) {
    height: auto;
    flex-basis: auto;
    border-top: none;
  }
`;

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  contentHeight: PropTypes.number.isRequired,
};

const MenuMarginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  @media (min-width: ${theme.topMenuBreakpoint}) {
    flex-direction: row;
    margin: 40px;
  }
`;

const LinkMenuItem = styled(Link)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: ${theme.titleColor};
  flex-shrink: 0;
  padding: 10px 10px 7px 10px;
  transition: 0.2s;
  border-bottom: 3px solid transparent;

  @media (min-width: ${theme.topMenuBreakpoint}) {
    margin-left: 10px;
  }

  @media (max-width: ${theme.demoBreakpoint}) {
    display: ${p => (p.largeScreenOnly ? "none" : "inline")};
  }

  &.link-active,
  &:active,
  &:hover {
    border-bottom: 3px solid ${theme.accentColor};
  }
`;

const ButtonMenuItem = styled(AccentLinkButton)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  flex-shrink: 0;
  padding: 4px 10px 4px 10px;

  @media (min-width: ${theme.topMenuBreakpoint}) {
    margin-left: 20px;
  }

  @media (min-width: ${theme.mobileBreakpoint}) {
    font-size: 16px;
  }

  @media (max-width: ${theme.demoBreakpoint}) {
    display: ${p => (p.largeScreenOnly ? "none" : "inline")};
  }
`;

const MenuItem = ({ title, url, type, largeScreenOnly }) => {
  switch (type) {
    case "link":
      return (
        <LinkMenuItem href={url} largescreenonly={largeScreenOnly}>
          {title}
        </LinkMenuItem>
      );
    case "button":
      return (
        <ButtonMenuItem href={url} largescreenonly={largeScreenOnly}>
          {title}
        </ButtonMenuItem>
      );
    default:
      return null;
  }
};

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["link", "button"]).isRequired,
  largeScreenOnly: PropTypes.bool,
};

export const HeaderBlock = () => {
  const menuRef = useRef(null);

  const menuContentHeight = menuRef.current ? menuRef.current.scrollHeight : 0;

  return (
    <MenuRow>
      <LogoLink href="/">
        <StaticQuery
          query={graphql`
            query LogoImgQuery {
              file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 158, height: 36)
                }
              }
            }
          `}
          render={data => <LogoImg fixed={data.file.childImageSharp.fixed} critical fadeIn={false} />}
        />
      </LogoLink>
      <Menu ref={menuRef} contentHeight={menuContentHeight}>
        <MenuMarginContainer>
          {MENU_ITEMS.map((menuItem, index) => (
            <MenuItem key={`menuItem${index}`} {...menuItem} />
          ))}
        </MenuMarginContainer>
      </Menu>
    </MenuRow>
  );
};
